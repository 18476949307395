.WelcomeSlider_ParentDiv {
  height: 100vh;
  /* background-image: url('../../../../images/photos/church_image.jpg'); */
  background-size: cover;
  background-position: center bottom;
  box-sizing: border-box;
  object-fit: cover;
  backface-visibility: hidden;
}

.video_div {
  height: 100vh;
  width: 99vw;
  align-items: center;
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
}

.video-style {
  margin: 0;
  padding: 0;
  height: 122vh;
}

.SliderControls__button {
  width: 5%;
  height: 10%;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin-top: 25%;
  z-index: 99;
}

.SliderControls__button_left {
  position: absolute;
  left: 0px;
}

.SliderControls__button_right {
  position: absolute;
  right: 0px;
}

.Slider2_ParentDiv {
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 7), rgba(0, 0, 0, 0.5)),
    url("../../../../images/photos/church_image.jpg");
  background-size: cover;
  background-position: center bottom;
  box-sizing: border-box;
  object-fit: cover;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  vertical-align: middle;
}

@keyframes animation1 {
  0% {
    font-size: 60px;
  }
  50% {
    font-size: 60px;
    opacity: 0.4;
  }
}

.HomeWelcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5vh;
  max-height: 100vh;
}

.HomeWelcome__title {
  color: #ffc107;
  font-size: 60px;
  font-family: "Arsenal", sans-serif;
  font-weight: 400;
  font-style: italic;
  text-shadow: 0.75px 0px 0px;
  letter-spacing: 1.5px;
  word-spacing: 1px;
  margin-bottom: 15px;
  text-align: center;
  animation: animation1 infinite 3s ease-in-out;
}

.HomeWelcome__title_place {
  color: #ffc107;
  font-size: 40px;
  font-family: "Arsenal", sans-serif;
  font-weight: 400;
  font-style: italic;
  text-shadow: 0.75px 0px 0px;
  letter-spacing: 1.5px;
  word-spacing: 1px;
  margin-bottom: 15px;
  text-align: center;
}

.HomeWelcome__title__name {
  word-spacing: -3px;
}

.HomeWelcome__text {
  color: #fff;
  font-size: 48px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 1.5px;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
  /* margin-bottom: 35px; */
  margin-top: 30vh;
  text-align: center;
}

.HomeWelcome__buttons {
  display: flex;
  justify-content: center;
}
.HomeWelcome__buttons .Button:not(:first-child) {
  margin-left: 15px;
}

.logo_image {
  width: 200px;
}

.slider-child {
  width: 100vw;
  background-size: cover;
  background-position: center bottom;
  box-sizing: border-box;
  object-fit: cover;
  backface-visibility: hidden;
}

.slider-parent {
  padding: 0px;
  margin: 0px;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center bottom;
  box-sizing: border-box;
  object-fit: cover;
  backface-visibility: hidden;
}

.slider2-name-parent:hover:after {
  box-shadow: 01px 1px 1px 1px black;
  background-color: rgba(0, 0, 0, 0.5); 
  padding: 10px;
}

@keyframes animate_running_border {

}


.Slider3_ParentDiv {
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 7), rgba(0, 0, 0, 0.5)),
    url("../../../../images/photos/church_image_old.jpg");
  background-size: cover;
  background-position: center bottom;
  box-sizing: border-box;
  object-fit: cover;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
  color: #f0da9b;
  font-size: 1.36rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 1.8rem;
  align-items: center;
  gap: 02rem;
}

.slider-verse-parent {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 80vw;
  align-items: center;
  /* background: rgba(48, 48, 44, 0.863); */
  padding: 20px;
  box-shadow: 15px 15px 15px 15px rgba(0, 0, 0, 0.863);
}

.slider-verse {
  display: flex;
  flex-direction: column;
}


