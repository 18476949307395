.nav-logo-div {
  display: flex;
  justify-items: center;
  padding-top: 0px;
}

.logo_image_n {
  width: 100px;
}

.nav-logo-subDiv1 {
  justify-items: center;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
}

.nav-logo-subDiv2 {
  justify-items: center;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  font-family: Georgia, serif;
  font-size: 20px;
}

.navbar {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;
}

.navbarHeader {
  left: 0px;
  margin: 0px;
}

.me-auto {
  font-size: 17px;
  display: flex;
  justify-items: right;
  margin-left: 30px;
  gap: 10px;
}

.nav_link_item {
  padding: 10px;
  display: inline-block;
  position: relative;
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
  border-radius: 2px;
  text-align: center;
  box-shadow: 1px 1px 1px 1px rgb(26, 24, 24);
}

.nav_link_item:hover {
  background-color: rgb(39, 39, 37);
  color: rgba(255, 255, 0, 0.562);
}
.nav_link_item:hover:after {
  transform: scaleX(1);
  transform-origin: bottom center;
}

.nav_link_item:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #c06223;
  transform-origin: bottom center;
  transition: transform 0.25s ease-out;
}

.nav_link_item_live
{
  font-size: 20px;
  font-weight: 550;
  background-color: red;
  color: white;
  padding: 10px;
  display: inline-block;
  position: relative;
  width: 90px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 1px 1px 1px 1px rgb(26, 24, 24);
  animation : animation_live infinite 1s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.nav_link_item_live:hover {
  background-color: rgb(245, 62, 62);
  color: rgba(0, 0, 0, 0.979);
  animation : off;
}
.nav_link_item_live:hover:after {
  transform: scaleX(1);
  transform-origin: bottom center;
}

@keyframes animation_live {
  0% {
    
  }
  50% {
    opacity: 0.7;
  }
}