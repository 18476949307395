.SocialMediaIcon {
  width: 45px;
  border-radius: 3px;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.15s ease-in-out 0s;
}
.SocialMediaIcon:hover {
  opacity: 0.85;
}

.SocialMediaIcon__image {
  width: 100%;
  height: 0px;
  padding-bottom: 100%;
  background-color: #fff;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.SocialMediaIcon--facebook .SocialMediaIcon__image {
  background-image: url("../socialmedia/facebook.png");
}
.SocialMediaIcon--whatsapp .SocialMediaIcon__image {
  background-image: url("../socialmedia/whatsapp.png");
}
.SocialMediaIcon--youtube .SocialMediaIcon__image {
  background-image: url("../socialmedia/youtube.png");
}
.SocialMediaIcon--instagram .SocialMediaIcon__image {
  background-image: url("../socialmedia/instagram.png");
}