.HomeAboutGridItem {
  flex-basis: calc(100% / 3);
}

.HomeAboutGridItem a {
  text-decoration: none;
}

.HomeAboutGridItem__holder {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 300px;
  min-width: 300px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.25s ease-in-out 0s;
}
.HomeAboutGridItem__holder:hover {
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
}

.HomeAboutGridItem__line {
  position: absolute;
  bottom: 0px;
  width: 0%;
  height: 2px;
  background-color: #000;
  opacity: 0;
  transition: width 0.25s ease-in-out 0s,
    opacity 0.25s ease-in-out 0s;
}
.HomeAboutGridItem__holder:hover .HomeAboutGridItem__line {
  width: 100%;
  opacity: 1.0;
}

.HomeAboutGridItem__text-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 0px;
}

.HomeAboutGridItem__text {
  color: var(--color-dark);
  font-size: 28px;
  font-family: "Arsenal", sans-serif;
  font-weight: 400;
  letter-spacing: 0.25px;
  text-shadow: 0.75px 0px 0px;
  margin-top: 25px;
  transition: color 0.25s ease-in-out 0s;
}
.HomeAboutGridItem__holder:hover .HomeAboutGridItem__text {
  color: var(--color-red);
}

.HomeAboutGridItem__icon {
  position: relative;
  width: 150px;
  height: 150px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transform: scale(0.9);
  filter: grayscale(0) saturate(1.15);
  opacity: 1.0;
  transition: transform 0.25s ease-in-out 0s,
    filter 0.25s ease-in-out 0s,
    opacity 0.25s ease-in-out 0s;
}

.HomeAboutGridItem__icon[data-icon="beliefs"] {
  left: -3px;
  background-image: url("./images/bible-icon-color.png");
}
.HomeAboutGridItem__icon[data-icon="ministries"] {
  left: -5px;
  background-image: url("./images/church-icon-color.png");
}
.HomeAboutGridItem__icon[data-icon="leadership"] {
  left: -10px;
  background-image: url("./images/pulpit-icon-color.png");
  background-size: 90% auto;
}

.HomeAboutGridItem__holder:hover .HomeAboutGridItem__icon {
  transform: scale(1.0);
  filter: grayscale(1);
  opacity: 0.35;
}
