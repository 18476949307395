.Footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.Footer__content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  align-self: center;
  padding: 10px;
  flex-wrap: wrap;
}

.credits
{
justify-content: center;
align-items: center;
color: var(--color-dark);
font-size: 14px;
font-family: "Roboto", sans-serif;
font-weight: 600;
text-align: center;
opacity: 0.5;
cursor: pointer;
}
.credits:hover{
  font-style: italic;
  color: black;
  opacity: 0.8;
}