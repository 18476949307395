.Page {
  position: relative;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.Page__content {
  position: relative;
  background-color: #fff;
  z-index: 1;
}

.Page__overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 1;
  z-index: 1;
  pointer-events: none;
  transition: opacity 0.5s ease-in 0s;
}

.Page--loaded .Page__overlay {
  opacity: 0;
}
