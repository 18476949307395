:root {
  --color-dark: #263238;
  --color-red: #e92a2a;
  --color-yellow: #ffc107;
  --color-yellow-pale: #ffd46c;
  --color-blue: #008ed3;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-color: #cccccc;
}

.no-scroll {
  overflow: hidden;
}
.no-scroll #root {
  overflow-y: hidden;
}

.wrapper {
  margin: 0px auto;
}
.wrapper--max {
  width: 100%;
}
.wrapper--inner {
  max-width: 1500px;
}
.wrapper--outer {
  max-width: 2000px;
}
.wrapper--content {
  max-width: 1250px;
}
.wrapper--text {
  max-width: 1000px;
}
.wrapper--800px {
  max-width: 800px;
}
.wrapper--640px {
  max-width: 640px;
}

a {
  text-decoration: none;
}

h2,
h3,
h4 {
  color: var(--color-dark);
  font-family: "Arsenal", sans-serif;
  font-weight: 700;
}

h2.h2--thin,
h3.h3--thin,
h4.h4--thin {
  font-weight: 400;
  text-shadow: 1px 0px 0px;
}

h2 {
  font-size: 36px;
  letter-spacing: 0.5px;
  line-height: 32px;
}

h3 {
  font-size: 28px;
  letter-spacing: 0.5px;
  line-height: 28px;
}

h4 {
  font-size: 24px;
  letter-spacing: 0.5px;
  line-height: 24px;
}

p {
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0.5px;
  text-shadow: 0.5px 0px 0px;
}
p.p--small {
  font-size: 16px;
  line-height: 22px;
}
p.p--indent {
  text-indent: 30px;
}

span.no-wrap {
  white-space: nowrap;
}

.absolute {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
